/* eslint-disable no-restricted-imports */
// eslint-disable-next-line
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { reduxForm,initialize } from "redux-form";
import { useSelector , useDispatch} from "react-redux";
import { Box, Divider, TextField } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

import { MicrositesContext } from "../../pages/microsites/MicrositesContextProvider";

import { convertFormData } from "../../../../_helpers/_utils/utils";

import { testimonialAPI } from "../../../setting/_crud/settingCrud";
import { addNewMicrosite, updateMicrosite,checkMicrositeSlug } from "../../crud/socialMedailCrud";

import DefaultModal from "../../../../partials/modal/DefaultModal";
import AddNewTestimonials from "../../partials/testimonials/AddNewTestimonials";
import ThemeButton from "../../../../partials/button/ThemeButton";
import MultiSelect from "../../../../partials/form/MultiSelect";

import useLoggedUserInfo from "../../../../hooks/useLoggedUserInfo";
import AddNewCommunityInvolvement from "../../partials/communityInvolvement/AddNewCommunityInvolvement"
import { getAllCommunityInvolvement, updateCommunityInvolvement, deleteCommunityInvolvement } from "../../../setting/_crud/settingCrud";

import MicrositeToggle from "./MicrositeToggle";
import MicrositeTextarea from "./MicrositeTextarea";
import MicrositeDropdown from "./MicrositeDropdown";
import MicrositeAutocomplete from "./MicrositeAutocomplete";
import MicrositeTestimonials from "./MicrositeTestimonials";
import MicrositeBlogs from "./MicrositeBlogs";
import MicrositeCommunityInvolvement from "./MicrositeCommunityInvolvement";
import PreListingTemplatePreview from "./PreListingTemplatePreview";

import './style/microsites.css';
import {getAllDiaryAppointmentList} from "../../../diary/_curd/diaryCurd";

const DEFAULT_FORM_VALUES = {
  selling_process: false,
  traps_and_pitfalls: false,
  red_flags: false,
  ceo_handle: false,
  wb_sales_team: false,
  wb_values: false,
  testimonials: false,
  community_involvement: false,
  blogs: false,
  agent_result: false,
  recently_sold: false,
  whats_next_text:"An agency agreement and a contract for sale will be prepared for you by your agent. Once signed and returned, we'll start the pre-campaign preparations, " +
      "such as attending to last minute repairs, home staging, photography & videography arrangement, and marketing materials creation & printing."
};
import moment from "moment";
import MicrositeDropdownMultiselect from "./MicrositeDropdownMultiselect";
import _debounce from 'lodash/debounce';


const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");


const NewMicrositeForm = ({ onSubmit, closeModal }) => {
  const {
    members,
    getListOfMembers,
    appointments,
    getListOfAppointments,
    availableTemplates,
    getListOfTemplates,
    currentMicrosite,
    isLoadingMembers,
    isLoadingTemplates
  } = useContext(MicrositesContext);

  const loggedUser = useLoggedUserInfo();

  const [selectedTemplate, setSelectedTemplate] = useState(
      currentMicrosite?.template ?? ""
  );
  const [micrositeType, setMicrositeType] = useState(
      currentMicrosite?.type?.id ?? ""
  );
  const [selectedTestimonials, setSelectedTestimonials] = useState({});
  const [selectedCommunity, setSelectedCommunity] = useState({});
  const [selectedBlogs, setSelectedBlogs] = useState({});
  const org = useSelector((state) => state.auth.user.organisation.id);
  const [formSettings, setFormSettings] = useState(DEFAULT_FORM_VALUES);

  const [showAddNewTestimonial, setShowAddNewTestimonial] = useState(false);
  const [showAddNewCommunityInvolvement, setShowAddNewCommunityInvolvement] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState({});
  const [showAddNewCommunity, setShowAddNewCommunity] = useState(false);
  const [content, setContent] = useState()
  const [CommunityInvolvement, setCommunityInvolvement] = useState([]);
  const [filteredCommunityInvolvement, setFilteredCommunityInvolvement] = useState([]);

  const [refetchTestimonials, setRefetchTestimonials] = useState(false);
  const [refetchCommunity, setRefetchCommunity] = useState(false);
  const [refetchBlogs, setRefetchBlogs] = useState(false);

  const [filteredAppointment, setFilteredAppointment] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedCoMember, setSelectedCoMember] = useState([]);
  const [appointmentFor, setAppointmentFor] = useState([]);
  const [isLoadingAppointments, setIsLoadingAppointments] = useState(false);
  const [slugErrorMessage, setSlugErrorMessage] = useState('');
  const [ salesMethodValue , setSalesMethodValue] = useState([]);
  const dispatch = useDispatch();
  const loadingData =
      isLoadingMembers ||
      isLoadingAppointments ||
      isLoadingTemplates ||
      !micrositeType;


  useEffect(() => {
    getListOfMembers();
    // getListOfAppointments();
    if (currentMicrosite?.id) {
      getListOfTemplates(currentMicrosite?.for);
    } else {

      getListOfTemplates(loggedUser?.id);
    }
  }, []);

  useEffect(() => {
    if (!loadingData) {
      if (currentMicrosite?.id) {
        const micrositeTestimonials = {};
        const micrositeCommunity = {};
        const micrositeBlogs = {};

        for (
            let i = 0;
            i <= currentMicrosite?.microsite_testimonials?.length - 1;
            i++
        ) {
          micrositeTestimonials[
              `testimonial_${currentMicrosite?.microsite_testimonials[i]?.id}`
              ] = true;
        }

        for (
            let i = 0;
            i <= currentMicrosite?.community_involvement?.length - 1;
            i++
        ) {
          micrositeCommunity[
              `community_${currentMicrosite?.community_involvement[i]?.id}`
              ] = true;
        }
        if (currentMicrosite?.type?.id == 2) {
          for (
              let i = 0;
              i <= currentMicrosite?.microsite_blogs?.length - 1;
              i++
          ) {
            micrositeBlogs[
                `blog_${currentMicrosite?.microsite_blogs[i]?.id}`
                ] = true;
          }
        }

        if (currentMicrosite?.type?.id == 3) {
          if ((JSON.parse(currentMicrosite?.microsite_template?.sales_method) ?? []).length > 0) {
            let sales_method_data = JSON.parse(currentMicrosite?.microsite_template?.sales_method) ?? [];
            setSalesMethodValue(sales_method_data);
          }
        }
        const newObj = {
          name: currentMicrosite?.name,
          slug: currentMicrosite?.slug,
          type: currentMicrosite?.type?.id,
          template: currentMicrosite?.template,
          appointment: currentMicrosite?.appointment.id,
          for: currentMicrosite?.for,
          secondary_for: currentMicrosite?.secondary_for,
          agent_letter: currentMicrosite?.microsite_template?.agent_letter,
          agent_intro: currentMicrosite?.microsite_template?.agent_intro,
          co_agent_intro: currentMicrosite?.microsite_template?.co_agent_intro,
          whats_next_text: currentMicrosite?.microsite_template?.whats_next_text ? currentMicrosite?.microsite_template?.whats_next_text : DEFAULT_FORM_VALUES.whats_next_text,
          selling_process: Boolean(currentMicrosite?.microsite_template?.selling_process),
          traps_and_pitfalls:
              Boolean(currentMicrosite?.microsite_template?.traps_and_pitfalls),
          red_flags: Boolean(currentMicrosite?.microsite_template?.red_flags),
          ceo_handle: Boolean(currentMicrosite?.microsite_template?.ceo_handle),
          agent_result: Boolean(currentMicrosite?.microsite_template?.agent_result || false),
          recently_sold: Boolean(currentMicrosite?.microsite_template?.recently_sold || false),
          // our_tips: Boolean(currentMicrosite?.microsite_template?.our_tips),
          getting_sold: Boolean(currentMicrosite?.microsite_template?.getting_sold),
          wb_sales_team: Boolean(currentMicrosite?.microsite_template?.wb_sales_team),
          wb_values: Boolean(currentMicrosite?.microsite_template?.wb_values),
          testimonials: Boolean(currentMicrosite?.microsite_template?.testimonials),
          blogs: Boolean(currentMicrosite?.microsite_template?.blogs),
          selectedTestimonials: micrositeTestimonials,
          selectedBlogs: micrositeBlogs,
          community_involvement: Boolean(currentMicrosite?.microsite_template?.community_involvement),
          selectedCommunity: micrositeCommunity,
          sales_method: salesMethodValue
        };

        setSelectedTestimonials(micrositeTestimonials);
        setSelectedCommunity(micrositeCommunity);
        setSelectedBlogs(micrositeBlogs);
        setFormSettings(newObj);
        if (currentMicrosite?.type?.id == 3) {
          dispatch(initialize('new-microsite', {
            sales_method: salesMethodValue
          }));
        }
        // setSelectedMember(currentMicrosite?.for)
        onMemberChange(currentMicrosite?.for);
        if (currentMicrosite?.secondary_for != null || currentMicrosite?.secondary_for != 0) {
          onSecondaryMemberChange(currentMicrosite?.secondary_for);
        }

      } else {
        if (!selectedTemplate) {
          setFormSettings({
            ...DEFAULT_FORM_VALUES,
            for: loggedUser?.id,
            name: formSettings?.name ?? "",
            agent_intro: loggedUser?.bio,
            agent_letter: "",
            type: micrositeType ?? "",
            template: selectedTemplate ?? "",
            secondary_for: ""

          });
          onMemberChange(loggedUser?.id);
        }

      }
      // filterAppointmentList( loggedUser?.contact?.id);
    }
  }, [
    loadingData,
    isLoadingMembers,
    isLoadingAppointments,
    isLoadingTemplates
  ]);


  //Todo:: Udawadi Need to fix this in future, currently it is getting the appointment details from the 100 appointment that is fetcht in starting
  // which will won't work in future when that appointment no longer available on the appointment list.
  // Need to fetch the appointment details if it is edited one.

  const filterAppointmentList = (selected_for) => {
    const filtered = appointments.filter((appointment) => appointment.for_contact_id === selected_for);
    setFilteredAppointment(filtered);
  };

  const getListOfAppointmentsForAgent = (for_contact = null, appointment_direction = true, apptType = 4, limit = 30) => {
    let queryString = `type=${apptType}&limit=${limit}`;

    if (appointment_direction) {
      queryString += `&from=${moment().format("YYYY-MM-DD")}`;
    } else {
      queryString += `&to=${moment().format("YYYY-MM-DD")}`;
    }

    if (for_contact) {
      queryString += `&for_contact=${for_contact}`;
    }
    getAllDiaryAppointmentList(queryString)
        .then((res) => {
          if (res?.status === 200) {
            const formattedAppointments = [];
            const data = res.data.data;
            console.log("data", data);
            data.forEach((appointment) => {
              const address = appointment?.prospect[0]?.property?.address;
              const formattedAddress = appointment?.meeting_location;
              formattedAppointments.push({
                id: appointment.id,
                for_contact_initial: appointment?.for_contact?.initial,
                for_contact_id: appointment?.for_contact?.contact?.id,
                date: new Date(appointment?.starts).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric"
                }),
                name: `${address?.street_unit ? `${address?.street_unit} ` : ""}${
                    address?.street_number
                } ${formattedAddress?.street?.name}, ${address?.street?.suburb?.name}`,
                suburb: address?.street?.suburb?.name,
                street_no: address?.street_number,
                street: address?.street?.name,
                formattedDate: new Date(appointment?.starts).toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  weekday: "long",
                  hour: "numeric",
                  minute: "2-digit"
                }),
                formattedStreet: formattedAddress?.street?.name
              });
            });
            setFilteredAppointment(formattedAppointments);
          } else {
            setFilteredAppointment([]);
          }
        })
        .catch((err) => {
          setFilteredAppointment([]);
        })
        .finally(() => setIsLoadingAppointments(false));
  };


  const onMemberChange = (e) => {
    const memberSelected = members.filter(member => member.id === e)[0];
    setSelectedMember(memberSelected);
  };
  const onSecondaryMemberChange = (e) => {
    const coMemberSelected = members.filter(member => member.id === e)[0];
    setSelectedCoMember(coMemberSelected);
  }

  const combineMember = () => {
    // Combine selectedMember and selectedCoMember into a single array
    const selectedMemberArray = selectedMember ? selectedMember.contact_for || null : null;
    const selectedCoMemberArray = selectedCoMember ? selectedCoMember.contact_for || null : null;
    const combinedSelection = Array.from([selectedMemberArray, selectedCoMemberArray]);

    const filteredSelection = combinedSelection.filter((item) => item !== null && item !== undefined);

    setAppointmentFor(filteredSelection);
  };

  useEffect(() => {
    if (selectedMember?.id || selectedCoMember?.id) {
      combineMember();
    }

  }, [selectedMember, selectedCoMember]);


  useEffect(() => {
    // Call getListOfAppointments whenever appointmentFor changes
    if (appointmentFor.length > 0) {
      getListOfAppointmentsForAgent(appointmentFor, (micrositeType === 1 ? true : false));
    }
  }, [appointmentFor, micrositeType]);

  const onFormFieldChange = (e, type = "input", name = null) => {
    const newObj = {...formSettings};
    if (name) {
      newObj[name] = e;
    } else {
      newObj[e?.target?.name] =
          type === "toggle" ? e?.target?.checked : e?.target?.value;
    }

    if (name === "for") {

      const selectedMember = members.filter(member => member.id === e)[0];
      newObj["agent_letter"] = "";
      newObj["agent_intro"] = selectedMember?.bio?.substring(0, 300);
      // filterAppointmentList(selectedMember?.contact_for);
      setSelectedMember(selectedMember);
    }

    if (name === "secondary_for") {
      const coMemberSelected = members.filter(member => member.id === e)[0];
      setSelectedCoMember(coMemberSelected);
      newObj["co_agent_intro"] = coMemberSelected?.bio?.substring(0, 300);
    }

    newObj["type"] = micrositeType;
    newObj["template"] = selectedTemplate;

    if (name && name === "appointment") {
      newObj[name] = e?.id;
      if (filteredAppointment.filter(app => app.id === e?.id)[0]) {
        const appointmentAddress = filteredAppointment.filter(
            app => app.id === e?.id
        )[0]?.name;

        //set the seleted appointment
        setSelectedAppointment(e ?? "");

        newObj["slug"] = slugify(
            `${loggedUser.organisation.identifier ??
            loggedUser.organisation.name} ${appointmentAddress}`
        );
      }

    }

    if (e?.target?.name === "slug") {
      //check either the given slug is available or not and it should be unique
      checkSlugUniqueness(e?.target?.value)
      //
    }

      setFormSettings(newObj);
    }
    ;

    const submitForm = async () => {
      const micrositeSettings = {
        ...formSettings,
        origin: 1,
        selectedTestimonials,
        selectedCommunity,
        selectedBlogs
      };

      if (!micrositeSettings?.name) {
        Swal.fire(
            "Error Saving Microsite",
            "You need to add a name to the microsite.",
            "error"
        );
        return false;
      }
      const isSlugUnique = await checkSlug(micrositeSettings?.slug);

      if (currentMicrosite?.id) {
        if (isSlugUnique) {
          updateMicrosite(currentMicrosite.id, micrositeSettings)
              .then(res => {
                if (res.status === 200) {
                  toast.success("Changes saved!");
                  closeModal();
                } else {
                  toast.error("Error while saving changes!");
                }
              })
              .catch(err => {
                toast.error(
                    `Error while sending request to update microsite! (${err})`
                );
              });
        }
      } else {
        if (isSlugUnique) {
          addNewMicrosite(micrositeSettings)
              .then(res => {
                if (res.status === 200) {
                  toast.success("New Microsite Saved!");
                  closeModal();
                } else {
                  toast.error("Error while saving microsite!");
                }
              })
              .catch(err => {
                toast.error(`Error while sending request to microsite! (${err})`);
              });
        }
      }
    };

    const closeTestimonialModal = () => {
      setShowAddNewTestimonial(false);
    };

    const closeCommunityInvolvementModal = () => {
      setShowAddNewCommunityInvolvement(false);
    };

    const handleOnSubmitTestimonial = async ({
                                               id,
                                               organisation,
                                               link,
                                               ...values
                                             }) => {
      let data = {
        ...values,
        type: 1,
        rental: null,
        listing: values.listing?.value
      };
      if (id) {
        data["_method"] = "PATCH";
      }
      const formData = convertFormData(data);
      return await testimonialAPI(id, formData)
          .then(res => {
            if (res.status === 200) {
              closeTestimonialModal();
              setRefetchTestimonials(true);
              toast.success("Testimonial Created Successfully");
            } else {
              toast.error(`Create Testimonial Failed!`);
            }
          })
          .catch(err => {
            toast.error(`Create Testimonial Failed (${err})`);
          });
    };

    const handleOnSubmitCommunityInvolvement = async ({assets, organisation, created_by, ...values}) => {
      const {id} = selectedCommunity;
      if (!values.image_url) {
        toast.error("Please select header image");

      } else if (content?.length === 0) {
        toast.error("Please fill the conent");
      } else {
        if (id) {
          values['_method'] = 'POST'
        }
        if (values.image_url instanceof Array) {
          values = {...values, image_url: values.image_url[0]}
        }
        values.content = content
        if (values.tags?.id) {
          values.tags = values.tags?.id
        } else {
          values.tags = values.tags
        }
        values.organisation = org;
        const formData = convertFormData(values);
        return await updateCommunityInvolvement(id, formData)
            .then(res => {
              getAllCommunityInvolvement("limit=100")
                  .then((res) => {
                    if (res.status === 200) {
                      setCommunityInvolvement(res.data.data);
                      setFilteredCommunityInvolvement(res.data.data);
                    }
                  })
                  .catch((err) => console.log("error loading testimonails", err));
              closeCommunityInvolvementModal()
              toast.success(`Community ${id ? 'Updated' : 'Created'} Successfully`)
            })
            .catch(err => {
              toast.error(`${id ? 'Update' : 'Create'} Community Involvement Failed`)
            })
      }
    };

    const handleTypeChange = (type) => {

      // if ((type === 2 || type === 3) ) {
      //   return;
      // }
      setFormSettings({...formSettings, type});
      setMicrositeType(type);
    };

    const handleClearSelection = () => {
      setSelectedCoMember(null);
      formSettings.co_agent_intro = "";
    };


    const handleTemplateSelect = (type) => {
      const temp = availableTemplates.filter(t => t.id === type)[0];

//fix the copy name on template name
      let newName = temp?.settings?.name || "";
      let copyRegex = /\(copy(\d+)?\)$/;

      if (copyRegex.test(newName)) {
        newName = newName.replace(copyRegex, (match, number) => {
          if (number === undefined) {
            return "(copy2)";
          } else {
            const incrementedNumber = parseInt(number, 10) + 1;
            return `(copy${incrementedNumber})`;
          }
        });
      } else {
        newName += " (copy)";
      }

      setFormSettings({...temp?.settings, template: type, name: newName});
      setSelectedTemplate(type);
      setMicrositeType(temp?.settings?.type);
      onMemberChange(temp?.settings?.for);
      if (temp?.settings?.secondary_for != null) {
        onSecondaryMemberChange(temp?.settings?.secondary_for);
      } else {
        handleClearSelection();
      }

    };

    const options = [
      {name: 'None', id: null},
      ...members // Assuming members is an array of available agents
      // Add the option to clear the selection
    ];
  // const checkSlugUniqueness = _debounce(checkSlug, 500);
  const checkSlug = async (slug) => {
    try {
      const response = await checkMicrositeSlug(slug);
      if (response.status === 200) {
        if (response.data.data == "") {
          setSlugErrorMessage('');
          return true;
        } else {
          if(response.data.data != currentMicrosite?.id){
            setSlugErrorMessage('This slug is already in use.');
            return false;
          } else {
            return true;
          }
        }
      }
    } catch (error) {
      console.error('Error checking slug uniqueness', error);
      // Handle any errors that occur during the check
    }
  }

  const checkSlugUniqueness = _debounce(checkSlug, 500);

    const sales_method_options = [
      {id: 1, name: "Auction"},
      {id: 2, name: "Private Treaty"},
      {id: 3, name: "By Tender"}
    ];

    // const isValidForm =
    //   formSettings.name &&
    //   formSettings.type &&
    //   formSettings.for &&
    //   formSettings.appointment &&
    //   formSettings.slug &&
    //   formSettings.agent_letter &&
    //   formSettings.agent_intro;

    let isValidForm = false;

    switch (micrositeType) {
      case 1:
        isValidForm =
            formSettings.name &&
            formSettings.type &&
            formSettings.for &&
            formSettings.appointment &&
            formSettings.slug &&
            formSettings.agent_letter &&
            formSettings.agent_intro;
        break;

      case 2:
        isValidForm =
            formSettings.name &&
            formSettings.type &&
            formSettings.for &&
            formSettings.slug &&
            formSettings.appointment &&
            formSettings.agent_letter;

        break;
      case 3:
        isValidForm =
            formSettings.name &&
            formSettings.type &&
            formSettings.for &&
            formSettings.slug &&
            formSettings.appointment &&
            formSettings.agent_letter;
        break;

      default:
        isValidForm = false;
        break;
    }


    return (
        <Box p={3}>
          <form className="settings-form" onSubmit={onSubmit}>
            <Row>
              <Col className="d-flex" style={{justifyContent: "space-between"}}>
                <Box>
                  <h5 className="title">
                    {currentMicrosite?.id ? "Edit" : "Create New"} Microsite
                  </h5>
                </Box>
                <Box className="w-50">
                  {currentMicrosite?.id ? (
                      <TextField
                          name={"name"}
                          placeholder={"Copy Existing Microsite"}
                          className="w-100"
                          value={currentMicrosite?.microsite_template?.name ?? ""}
                          disabled={currentMicrosite?.id}
                      />
                  ) : (
                      <MultiSelect
                          name="template"
                          label={"Copy Existing Microsite"}
                          options={availableTemplates ?? []}
                          isMulti={false}
                          onChange={handleTemplateSelect}
                          value={formSettings?.template ?? ""}
                          divStyle={{marginTop: "-16px"}}
                          disabled={currentMicrosite?.id}
                      />
                  )}
                </Box>
              </Col>
              <Col>
                <TextField
                    name={"name"}
                    placeholder={"Microsite Name"}
                    className="w-100"
                    onChange={onFormFieldChange}
                    value={formSettings?.name ?? ""}
                    disabled={currentMicrosite?.id}
                    error={!formSettings?.name}
                />
              </Col>
              <Col className="d-flex" style={{justifyContent: "end"}}>
                <MultiSelect
                    name="type"
                    label={"Microsite Type"}
                    isMulti={false}
                    options={[
                      {id: 1, name: "Pre-Listing"},
                      {id: 2, name: "Post-Listing (Listed)"},
                      {id: 3, name: "Post-Listing (Not Listed)"}
                    ]}
                    onChange={handleTypeChange}
                    value={formSettings?.type ?? ""}
                    divStyle={{marginTop: "-16px", width: "100%"}}
                    disabled={currentMicrosite?.id}
                    error={!formSettings?.type}
                />

                <div className="pl-2">
                  <ThemeButton className="lightGrey-button" onClick={closeModal}>
                    Cancel
                  </ThemeButton>
                </div>
                <div className="pl-2">
                  <ThemeButton
                      className="submit-button"
                      disabled={loadingData || !isValidForm}
                      onClick={submitForm}
                  >
                    Save
                  </ThemeButton>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                {/*Filter the left column based on the selected settings microsite types*/}

                <MicrositeDropdown
                    label="Agent"
                    name="for"
                    value={formSettings?.for ?? loggedUser?.id}
                    placeholder="Select Agent Profile"
                    options={members}
                    disabled={loadingData}
                    onChange={onFormFieldChange}
                    isLoading={loadingData}
                    error={!(formSettings?.for ?? loggedUser?.id)}
                />


                <MicrositeDropdown
                    label="Co-listing Agent"
                    name="secondary_for"
                    value={formSettings?.secondary_for ?? ""}
                    placeholder="Select Co-listing Agent Profile"
                    options={options}
                    disabled={loadingData}
                    onChange={onFormFieldChange}
                    isLoading={loadingData}
                    onClear={handleClearSelection}
                />


                {currentMicrosite?.id ? (
                    <MicrositeTextarea
                        label="Listing Appointment"
                        name="appointment_address"
                        value={currentMicrosite?.address?.search_address}
                        disabled={true}
                        multiline={false}
                    />
                ) : (
                    <MicrositeAutocomplete
                        label="Listing Appointment"
                        name="appointment"
                        value={formSettings?.appointment}
                        placeholder="Select Appointment"
                        options={filteredAppointment}
                        disabled={loadingData}
                        onChange={(e, value) =>
                            onFormFieldChange(value, "autocomplete", "appointment")
                        }
                        isLoading={loadingData}
                        error={!formSettings?.appointment}
                        fullWidth
                    />
                )}
                <MicrositeTextarea
                    name="slug"
                    placeholder="Microsite Slug"
                    label="Microsite Slug"
                    value={formSettings?.slug ?? ""}
                    disabled={loadingData}
                    onChange={onFormFieldChange}
                    multiline={false}
                    helperText={slugErrorMessage}
                    error={!formSettings?.slug || slugErrorMessage}
                />
                {/*todo::Need to check either the slug already exist or not while creating*/}
                {/*{micrositeType === 1 ? (*/}
                {/*    <>*/}
                {/*      <MicrositeTextarea*/}
                {/*          name="slug"*/}
                {/*          placeholder="Microsite Slug"*/}
                {/*          label="Microsite Slug"*/}
                {/*          value={formSettings?.slug ?? ""}*/}
                {/*          disabled={loadingData}*/}
                {/*          onChange={onFormFieldChange}*/}
                {/*          multiline={false}*/}
                {/*          error={!formSettings?.slug}*/}
                {/*      />*/}

                {/*    </>*/}
                {/*) : null }*/}

                <MicrositeTextarea
                    name="agent_letter"
                    placeholder="Write your letter in less than 300 characters."
                    label="Agent Letter"
                    value={formSettings?.agent_letter}
                    disabled={loadingData}
                    onChange={onFormFieldChange}
                    error={!formSettings?.agent_letter}
                    maxlength={micrositeType === 1 ? 500 : 1500}
                />


                {micrositeType === 1 ? (
                    <>
                      <MicrositeTextarea
                          name={`agent_intro`}
                          placeholder="Write your introduction in less than 300 characters."
                          label="Agent Introduction"
                          value={formSettings?.agent_intro}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                          error={!formSettings?.agent_intro}
                      />
                      {/*Co Agent intro*/}
                      {selectedCoMember?.first_name && (
                          <MicrositeTextarea
                              name={`co_agent_intro`}
                              placeholder="Write Co-lisitng agent introduction in less than 300 characters."
                              label="Co-listing Agent Introduction"
                              value={formSettings?.co_agent_intro}
                              disabled={loadingData}
                              onChange={onFormFieldChange}
                              error={!formSettings?.co_agent_intro}
                          />
                      )}

                    </>
                ) : null}


                {micrositeType === 1 ? (
                    <>
                      <MicrositeToggle
                          label="Selling Process"
                          name="selling_process"
                          value={formSettings?.selling_process}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                      />

                      <MicrositeToggle
                          label="Wiseberry Values"
                          name="wb_values"
                          value={formSettings?.wb_values}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                      />

                      <MicrositeToggle
                          label="CEO Handles Complaints"
                          name="ceo_handle"
                          value={formSettings?.ceo_handle}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                      />
                      <MicrositeToggle
                          label="Wiseberry Sales Team"
                          name="wb_sales_team"
                          value={formSettings?.wb_sales_team}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                      />
                    </>
                ) : null}


                {/*            <MicrositeToggle
              label="Wiseberry Values"
              name="wb_values"
              value={formSettings?.wb_values}
              disabled={loadingData}
              onChange={onFormFieldChange}
            />

            <MicrositeToggle
                label="CEO Handles Complaints"
                name="ceo_handle"
                value={formSettings?.ceo_handle}
                disabled={loadingData}
                onChange={onFormFieldChange}
            />*/}

                {micrositeType === 1 ? (
                    <>
                      <MicrositeTestimonials
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                          value={formSettings?.testimonials}
                          onSelect={setSelectedTestimonials}
                          selected={selectedTestimonials}
                          refetch={refetchTestimonials}
                          setRefetch={setRefetchTestimonials}
                          showModal={setShowAddNewTestimonial}
                      />

                      <MicrositeCommunityInvolvement
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                          value={formSettings?.community_involvement}
                          onSelect={setSelectedCommunity}
                          selected={selectedCommunity}
                          refetch={refetchCommunity}
                          CommunityInvolvement={CommunityInvolvement}
                          setCommunityInvolvement={setCommunityInvolvement}
                          filteredCommunityInvolvement={filteredCommunityInvolvement}
                          setFilteredCommunityInvolvement={setFilteredCommunityInvolvement}
                          setRefetch={setRefetchCommunity}
                          showModal={setShowAddNewCommunityInvolvement}
                      />

                    </>
                ) : null}


                {micrositeType === 2 ? (
                    <>
                      <MicrositeTextarea
                          name="whats_next_text"
                          placeholder="Write what's next information."
                          label="What's Next Information"
                          value={formSettings?.whats_next_text}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                          error={!formSettings?.whats_next_text}
                          maxlength={1000}
                      />

                      <MicrositeToggle
                          label="Getting Sold"
                          name="getting_sold"
                          value={formSettings?.getting_sold}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                      />
                      <MicrositeBlogs
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                          value={formSettings?.blogs}
                          onSelect={setSelectedBlogs}
                          selected={selectedBlogs}
                          refetch={refetchBlogs}
                          setRefetch={setRefetchBlogs}
                          // showModal={false}
                      />
                    </>
                ) : null}
                {micrositeType === 3 ? (
                    <>
                      <MicrositeToggle
                          label="Wiseberry Sales Process"
                          name="selling_process"
                          value={formSettings?.selling_process}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                      />

                      <MicrositeDropdownMultiselect
                          label="Sales Method"
                          name="sales_method"
                          isMulti={true}
                          options={sales_method_options}
                          disabled={loadingData}
                          isLoading={loadingData}
                          onChange={onFormFieldChange}
                          value={salesMethodValue}
                      />

                      <MicrositeToggle
                          label="Agent's Result"
                          name="agent_result"
                          value={formSettings?.agent_result}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                      />
                      <MicrositeToggle
                          label="Recently Sold"
                          name="recently_sold"
                          value={formSettings?.recently_sold}
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                      />

                      <MicrositeTestimonials
                          disabled={loadingData}
                          onChange={onFormFieldChange}
                          value={formSettings?.testimonials}
                          onSelect={setSelectedTestimonials}
                          selected={selectedTestimonials}
                          refetch={refetchTestimonials}
                          setRefetch={setRefetchTestimonials}
                          showModal={setShowAddNewTestimonial}
                      />
                    </>
                ) : null}

              </Col>
              <Divider orientation="vertical" flexItem/>
              <Col className="ml-3 preview_microsites" style={{borderStyle: "ridge"}}>
                {!loadingData && (
                    <PreListingTemplatePreview
                        micrositeType={micrositeType}
                        selling={formSettings?.selling_process}
                        traps={formSettings?.traps_and_pitfalls}
                        redflags={formSettings?.red_flags}
                        ceohandle={formSettings?.ceo_handle}
                        team={formSettings?.wb_sales_team}
                        values={formSettings?.wb_values}
                        testimonial={formSettings?.testimonials}
                        comm={formSettings?.community_involvement}
                        letter={formSettings?.agent_letter}
                        intro={formSettings?.agent_intro}
                        coIntro={formSettings?.co_agent_intro}
                        profile={loggedUser?.contact ?? null}
                        member={selectedMember}
                        coMember={selectedCoMember}
                        appointment={selectedAppointment}
                        gettingSold={formSettings?.getting_sold}
                        blogs={formSettings?.blogs}
                        agentResult={formSettings?.agent_result}
                        recentlySold={formSettings?.recently_sold}
                    />
                )}
              </Col>
            </Row>
          </form>

          {showAddNewTestimonial ? (
              <DefaultModal
                  size="xl"
                  show={showAddNewTestimonial}
                  onHide={closeTestimonialModal}
              >
                <AddNewTestimonials
                    onSubmit={handleOnSubmitTestimonial}
                    selectedTestimonial={{}}
                    closeModal={closeTestimonialModal}
                    closeWithAlert={closeTestimonialModal}
                />
              </DefaultModal>
          ) : null}
          {showAddNewCommunityInvolvement ? (
              <DefaultModal
                  size="xl"
                  show={showAddNewCommunityInvolvement}
                  onHide={closeCommunityInvolvementModal}
              >
                <AddNewCommunityInvolvement
                    onSubmit={handleOnSubmitCommunityInvolvement}
                    closeModal={closeCommunityInvolvementModal}
                    closeWithAlert={closeCommunityInvolvementModal}
                    selectedCommunityInvolvement={selectedBlog}
                    setSelectedCommunityInvolvement={setSelectedBlog}
                    content={content}
                    setContent={setContent}
                />
              </DefaultModal>
          ) : null}
        </Box>
    );

};

export default reduxForm({
  form: "new-microsite",
  enableReinitialize: true
})(NewMicrositeForm);
