import React, { useEffect, useState, Fragment, useContext } from "react";
import { getProspectById } from "../../curd/prospectsCrud";
import {
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  Divider,
  LinearProgress,
  Snackbar,
} from "@material-ui/core";

import "./_buyerProfileModal.scss";
import ProspectNotes from "./notes/ProspectNotes";
import ProspectProfileHeader from "./prospectProfileHeader/ProspectProfileHeader";
import ProspectProfileSideBar from "./prospectProfileSideBar/ProspectProfileSideBar";
import ProspectTimeline from "./timeline/ProspectTimeline";
import ProspectMail from "./mail/ProspectMail";
import ProspectMap from "./map/ProspectMap";
import { Row, Col } from "react-bootstrap";
import LoadingSkeleton from "../../../../partials/display/skeleton/LoadingSkeleton";
import ProspectDetails from "./details/ProspectDetails";
import ProspectMatched from "./ matched/ProspectMatched";
import { ProspectProfileContext } from "./ProspectProfileContextProvider";
import ProspectContactsWrap from "./contacts/ProspectContactsWrap";
import _isEmpty from "lodash/isEmpty";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const ProspectProfileModalBody = (props) => {
  const {
    prospectProfile,
    setProspectProfile,
    prospectId,
    prospectLoading,
    searchForContacts,
    searchForContactsMessage,
  } = useContext(ProspectProfileContext);

  const { closeModal, updateTable, setPageCount, otherResData } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (prospectId) {
      getProspectById(prospectId)
        .then(({ data }) => {
          setProspectProfile(data.data);
        })
        .catch(() => {});
    }
  }, [prospectId]);

  return (
    <>
      {prospectLoading ? (
        <LinearProgress />
      ) : (
        <div style={{ height: "4px" }}></div>
      )}
      {!_isEmpty(prospectProfile) ? (
        <>
          <Row className="pb-2">
            <Col lg={{ span: 9, offset: 3 }}>
              <ProspectProfileHeader
                closeModal={closeModal}
                prospectId={prospectId}
                selectedHeaderTab={value}
                updateTable={updateTable}
                setPageCount={setPageCount}
                otherResData={otherResData}
                prospectProfile={prospectProfile}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={4} xl={3} className="modal-sidebar">
              <ProspectProfileSideBar
                closeModal={closeModal}
                profile={prospectProfile}
                // searchForContacts={prospectProfile?.contacts?.length === 0}
              />
              <Divider orientation="vertical" flexItem />
            </Col>

            <Col lg={8} xl={9} className="pl-lg-1">
              <AppBar position="static" className="app-bar pb-2">
                <Tabs
                  className="tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{
                    style: { background: "transparent" },
                  }}
                >
                  <Tab label="Notes" {...a11yProps(0)} />
                  <Tab label="Contacts" {...a11yProps(1)} />
                  <Tab label="Details" {...a11yProps(2)} />
                  <Tab label="Matched" {...a11yProps(3)} />
                  {/*<Tab label="Timeline" {...a11yProps(4)} />*/}
                  <Tab label="Mail" {...a11yProps(5)} />
                  <Tab label="Map" {...a11yProps(6)} />
                </Tabs>
              </AppBar>
              <div className="contact-profileBody pl-5">
                <div className={classes.root}>
                  <TabPanel className="single-tab-body" value={value} index={0}>
                    <ProspectNotes
                      selectedHeaderTab={value}
                      setPageCount={setPageCount}
                      profileId={prospectId}
                      profile={prospectProfile}
                      name="test"
                      updateTable={updateTable}
                      setPageCount={setPageCount}
                    />
                  </TabPanel>
                  <TabPanel className="single-tab-body" value={value} index={1}>
                    <ProspectContactsWrap
                      profile={prospectProfile}
                      profileId={prospectId}
                      updateTable={updateTable}
                    />
                  </TabPanel>
                  <TabPanel className="single-tab-body" value={value} index={2}>
                    <ProspectDetails profile={prospectProfile} />
                  </TabPanel>
                  <TabPanel className="single-tab-body" value={value} index={3}>
                    <ProspectMatched
                      profileId={prospectId}
                      profile={prospectProfile}
                    />
                  </TabPanel>
                  {/*We removed timeline since there is no record found for prospect at this moment will add later on.*/}
                  {/*<TabPanel className="single-tab-body" value={value} index={4}>
                    <ProspectTimeline profileId={prospectId} />
                  </TabPanel>*/}
                  <TabPanel className="single-tab-body" value={value} index={5}>
                    <ProspectMail
                      profileId={prospectId}
                      profile={prospectProfile}
                    />
                  </TabPanel>
                  <TabPanel className="single-tab-body" value={value} index={6}>
                    <ProspectMap prospectProfile={prospectProfile} />
                  </TabPanel>
                </div>
              </div>
            </Col>
          </Row>

          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={searchForContacts}
            message={searchForContactsMessage}
            style={{ top: "50%" }}
          />
        </>
      ) : (
        <LoadingSkeleton />
      )}
    </>
  );
};
export default ProspectProfileModalBody;
